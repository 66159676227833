@import '../../sass/colors';
@import '../../sass/layout';

:local(.root) {
  display: inline-block;
  width: 100%;
}

:local(.headerInfo) {
  position: relative;
  text-align: center;
  & > span {
    display: inline-block;
    margin-bottom: 4px;
    line-height: 22px;
  }
  & > p {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
  }
  & > em {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 56px;
    background-color: $batch-transaction-view-item-bg;
  }
}


:local(.card) {
  @media (max-width: $screen-sm) {
    .ant-col {
      margin-bottom: 20px;
    }
  }
}
